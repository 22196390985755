import { FormProvider, useForm } from 'shared-scope/components/Form';
import { useLocation, useNavigate } from 'react-router-dom';
import useAlert from 'shared-scope/hooks/useAlert';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { s } from 'i18n';
import Page from 'layout/Page';
import { Step, Stepper } from 'shared-scope/components/Stepper';
import {
  SchedulerLabel, FormField, request, Sources,
} from 'products/shared/Campaign';
import {
  Actions, StepGeneral, StepQuestions, StepScheduler,
  StepSettings, StepTarget, NewPollRow,
} from 'products/shared/Campaign/steps';
import { useRequest } from '@xeebi/neru';
import { Typography } from '@mui/material';
import { CountryCapability } from 'graphql-api';
import { getCampaignData } from 'products/Poll/helpers';

const back = '/poll';

export default function NewPoll() {
  const form = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const { addSuccess, addError } = useAlert();
  const {
    isLoading, error, fetch: createPoll, result,
  } = useRequest(request.createCampaign);

  const [schedulerOn, setSchedulerOn] = useState(false);
  const [scheduleTimes, setScheduleTimes] = useState<string | null>(null);
  const templateId = new URLSearchParams(location.search).get('templateId');

  const setInitial = useMemo(() => form.setInitial, [form]);
  const onSubmit = useCallback(async (row: NewPollRow) => {
    await createPoll(getCampaignData(row, schedulerOn));
  }, [schedulerOn, createPoll]);

  const onDraft = useCallback(async () => {
    const row = form.getRow();
    if (row && form.validate()) {
      await createPoll(getCampaignData(row, schedulerOn, true));
    }
  }, [schedulerOn, createPoll, form]);

  useEffect(() => {
   setInitial({
     second_stage_resend_question_if_no_answer_min: '0',
    });
  }, [setInitial]);


  useEffect(() => {
    error && addError(s('Error creating a new poll'));
  }, [error, addError]);

  useEffect(() => {
    result && addSuccess(s('New poll added successfully'));
    result && navigate(back);
  }, [result, addSuccess, navigate]);

  useEffect(() => {
    const st = (form.getValue(FormField.route) as CountryCapability)?.scheduleTimes || null;
    setScheduleTimes(st);
    if (st) {
      setSchedulerOn(true);
    }
  }, [form]);

  return (
    <Page title={s('Create new poll')} back={back} loading={isLoading}>
      <FormProvider form={form} onSubmit={onSubmit}>
        <Stepper>
          <Step label={s('General')}>
            <StepGeneral titleFieldName={s('Poll name')} />
          </Step>
          <Step label={s('Target Audience')}>
            <StepTarget
              avail={[Sources.excel, Sources.text, Sources.phonebook]}
              targetType="poll"
            />
          </Step>
          <Step label={s('Settings')}>
            <StepSettings
              sourceType={form.getValue(FormField.sourceType)}
              sourceValue={form.getValue(FormField.sourceValue) || null}
            />
          </Step>
          <Step label={s('Questions')}>
            <StepQuestions
              sourceType={form.getValue(FormField.sourceType)}
              maxAttachmentSize={(form.getValue(FormField.route) as CountryCapability)?.maxAttachmentSize || 0}
              templateId={templateId ? +templateId : null}
            />
          </Step>
          <Step label={scheduleTimes ? s('Schedule') : <SchedulerLabel value={schedulerOn} onChange={setSchedulerOn} />}>
            {schedulerOn
              ? <StepScheduler
                  mask={scheduleTimes}
              />
              : <Typography variant="subtitle1">{s('Poll will be sent now')}</Typography>}
          </Step>
        </Stepper>
        <Actions
          submitText={s('Create new poll')}
          onDraft={onDraft}
          onCancel={() => navigate(back)}
        />
      </FormProvider>
    </Page>
  );
}


