import React, { memo, useEffect, useMemo } from 'react';
import { NewCampaignRow, StepSettingsRow } from 'products/shared/Campaign/steps/types';
import { FormController, useFormContext } from 'shared-scope/components/Form';
import { Grid, Stack, Typography } from '@mui/material';
import { s } from 'i18n';
import { get } from 'lodash';
import { z } from 'zod';
import {
 ErrorText, FilterForm, MappingForm, MappingTable,
} from '../components';
import {
  CampaignType,
  ContactListFilter,
  FormField,
  Mapping,
  MappingData,
  MappingFormField,
  MappingFormValue,
  Sources,
  SourceValue,
} from '../types';


const MMappingTable = memo(MappingTable);

export default function StepSettings({ sourceType, sourceValue }: StepSettingsProps) {
  const { form: { setValue }, formRow } = useFormContext<NewCampaignRow>();

  const mappingFormFields: MappingFormField[] = useMemo(() => {
    const fields: MappingFormField[] = [];
    const preparedData = sourceType === Sources.phonebook || sourceType === Sources.campaign;
    const fileType = sourceValue?.data?.file_type;

    !preparedData && fields.push(MappingFormField.cutDigits, MappingFormField.addPrefix);
    fileType === 'excel' && fields.push(MappingFormField.sheets);
    !preparedData && fileType === 'csv' && fields.push(MappingFormField.csvDelimiter, MappingFormField.csvQuote);
    sourceType === Sources.excel && fields.push(MappingFormField.skipTop);
    preparedData && fields.push(MappingFormField.random);

    return fields;
  }, [sourceType, sourceValue?.data?.file_type]);

  useEffect(() => {
    setValue(FormField.mappingParam, {});
    setValue(FormField.filter, {});
  }, [sourceType, setValue]);

  useEffect(() => {
    if (sourceValue?.data?.delimiter) {
      setValue(FormField.mappingParam, (prev: MappingFormValue) => ({
        ...prev,
        delimiter: sourceValue?.data?.delimiter,
      }));
    }
  }, [sourceValue?.data?.delimiter, setValue]);

  const noSettings = sourceType === Sources.responder
    ? s('Responder campaigns don\'t have any settings')
    : s('Specify the target audience to customize the settings');

  return sourceType && sourceValue ? (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item xs={12} lg={4} xl={3}>
        <Stack spacing={3}>
          <FormController<MappingFormValue>
            name={FormField.mappingParam}
            renderParams={{ mappingFormFields }}
            render={({ value, onChange, renderParams }) => (
              <MappingForm
                value={value || {}}
                onChange={onChange}
                fields={renderParams?.mappingFormFields || []}
                showRandom={formRow?.type === CampaignType.drip ? (formRow?.send_to_existing_contacts || false) : true}
              />
            )}
          />
          {sourceType === Sources.phonebook
            ? <FormController<ContactListFilter>
                name={FormField.filter}
                render={({ value, onChange }) => (
                  <FilterForm
                    value={value || {}}
                    onChange={onChange}
                  />
              )}
            /> : null}
        </Stack>
      </Grid>
      <Grid item xs={12} lg={8} xl={9}>
        <FormController<Mapping>
          name={FormField.mapping}
          validateRule={isSetPhoneColumn}
          renderParams={{
            sourceType,
            sourceValue: sourceValue.data,
            mappingParam: formRow.mapping_param,
          }}
          render={({
           value, onChange, error, renderParams,
          }) => (
            <>
              {error && <ErrorText>{error}</ErrorText>}
              <MMappingTable
                value={value || {}}
                onChange={onChange}
                mode={renderParams?.sourceType}
                data={renderParams?.sourceValue as MappingData}
                mappingParams={renderParams?.mappingParam || {}}
              />
            </>
          )}
        />
      </Grid>
    </Grid>
  ) : <Typography variant="subtitle1">{noSettings}</Typography>;
}


type StepSettingsProps = {
  sourceType?: Sources
  sourceValue: SourceValue
};

const isSetPhoneColumn = (row: StepSettingsRow) => z.custom(
  () => get(row.mapping, 'phone_number', -1) !== -1,
  s('Please define a column with phone numbers'),
);
